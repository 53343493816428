import Card1 from "@assets/delight_card_1.png";
import Card2 from "@assets/delight_card_2.png";
import Card3 from "@assets/delight_card_3.png";
import Card4 from "@assets/delight_card_4.png";
import logo from "@assets/logo.png";
import { Flex, VStack, Image, Box, Button } from "@chakra-ui/react";
import { ArrowLeft, Tickets } from "lucide-react";
import { useNavigate } from "react-router-dom";

const About8club = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
      overflowY="auto"
      sx={{ scrollbarWidth: "none" }}
      w="100%"
      h="100%"
      background="black"
      pt="1.25rem"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        position="relative"
        w="100%"
      >
        <Box position="absolute" left="1rem">
          <ArrowLeft size={24} onClick={handleBack} color="white" />
        </Box>
        <Box>
          <Image src={logo} />
        </Box>
      </Flex>
      <VStack
        spacing={4}
        mx="1rem"
        pt="3rem"
        pb="6rem"
        h="auto"
        overflow="auto"
      >
        <Image src={Card1} background="#101010B2" />
        <Image src={Card2} background="#101010B2" />
        <Image src={Card3} background="#101010B2" />
        <Image src={Card4} background="#101010B2" />
      </VStack>
      <Box
        position="absolute"
        bottom="0"
        width="100%"
        bg="b3"
        backdropFilter="auto"
        backdropBlur="12px"
        p="1rem"
        display="flex"
        justifyContent="center"
      >
        <Button
          colorScheme="#ffffff"
          width="full"
          height="3.5rem"
          bgGradient="silver"
          border="1px solid rgba(128, 128, 128, 0.5)"
          borderRadius="0.5rem"
          leftIcon={<Tickets size={16} color="white" />}
          onClick={handleBack}
        >
          Got it, request an Invite
        </Button>
      </Box>
    </Box>
  );
};

export default About8club;
