import logo from "@assets/logo.png";
import { Box, HStack, Image } from "@chakra-ui/react";
import { Headset } from "lucide-react";

import { whatsappLink } from "@/config";

const Header: React.FC = () => {
  return (
    <HStack justifyContent="space-between" p="1rem">
      <Image src={logo} alt="Logo" height="16px" width="auto" />
      <Box
        borderRadius="0.5rem"
        background="##FFFFFF05"
        border="0.5px solid #FFFFFF29"
        p="0.5rem"
      >
        <Headset
          size={16}
          color="white"
          onClick={() => window.open(whatsappLink, "_blank")}
        />
      </Box>
    </HStack>
  );
};

export default Header;
