import dayjs from "dayjs";

export const getShortDate = (date: Date): string => {
  const dateObj = dayjs(date);
  return dateObj.format("D MMM 'YY");
};

export const getLongDay = (date: Date): string => {
  const dateObj = dayjs(date);
  return dateObj.format("dddd");
};

export const getShortTime = (date: Date): string => {
  const dateObj = dayjs(date);
  return dateObj.format("h:mm A");
};
