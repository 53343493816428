import * as Sentry from "@sentry/react";
import { create } from "zustand";

import { recordHotspotAttribution } from "../repository/edge.server";

export interface HotspotAttributionStore {
  phoneInputValue: string;
  isProcessingRequest: boolean;
  isPhoneNumberValid: boolean;
  isSuccessful: boolean;

  setPhoneInputValue: (phone: string) => void;
  setDefaultPhone: (phone: string) => void;
  submitAttributionRequest: (shareCode: string) => Promise<void>;
}

const getDefaultPhone = () => localStorage.getItem("defaultPhone") ?? "";

const phoneNumberPattern = /^[6-9]\d{9}$/;

const useAttributionStore = create<HotspotAttributionStore>((set, get) => ({
  // Initiate Store with default values
  phoneInputValue: getDefaultPhone(),
  isProcessingRequest: false,
  isPhoneNumberValid: phoneNumberPattern.test(getDefaultPhone()),
  isSuccessful: false,

  setPhoneInputValue: (phone) => {
    // Disallow phone numbers longer than 10 digits
    if (phone.length > 10) {
      return;
    }
    const isValidPhone = phoneNumberPattern.test(phone);
    set({ isPhoneNumberValid: isValidPhone, phoneInputValue: phone });
  },

  // Set the default phone number in localstorage for future use
  setDefaultPhone: (phone) => {
    localStorage.setItem("defaultPhone", phone);
  },

  // Submit request for notional attribution
  submitAttributionRequest: async (shareCode: string) => {
    const phone = get().phoneInputValue;

    // Perform validation on phone number
    const isValidPhone = get().isPhoneNumberValid;
    if (!isValidPhone || !phone) {
      return;
    }

    // Start relevant loaders
    set({ isProcessingRequest: true });

    // Record the attribution request
    try {
      await recordHotspotAttribution(shareCode, phone);
      set({ isSuccessful: true });
    } catch (e: unknown) {
      Sentry.captureException(e);
      set({ isSuccessful: false });
    } finally {
      // Stop loaders
      set({ isProcessingRequest: false });
    }

    // Set the default phone number in localstorage
    get().setDefaultPhone(phone);
  },
}));

export default useAttributionStore;
