import verified from "@assets/verified.png";
import { Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Users } from "lucide-react";
import React from "react";

import HotspotHeaderTitle from "../components/HotspotHeaderTitle";
import { HotspotDetailsDTO } from "../repository/entities";

import HotspotDetailHeader from "./HotspotDetailHeader";
import RequestInviteDrawer from "./RequestInviteDrawer";

import Header from "@/components/Header";

const slideUpVariants = {
  hidden: { y: -100, opacity: 0, height: 0 },
  visible: { y: 0, opacity: 1, height: "auto" },
  exit: { y: -100, opacity: 0, height: 0 },
};

const fadeOutVariants = {
  hidden: { opacity: 0, height: 0 },
  visible: { opacity: 1, height: "auto" },
};

const bannerVariants = {
  inactive: {
    height: "560px",
    margin: "0 1rem",
    borderRadius: "0.75rem",
  },
  active: {
    height: "400px",
    margin: "0",
    borderRadius: "0",
    y: "-65px",
  },
};

interface HotspotPreviewCardProps {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  hotspot: HotspotDetailsDTO;
}

const HotspotPreviewCard: React.FC<HotspotPreviewCardProps> = ({
  isActive,
  setIsActive,
  hotspot,
}) => {
  return (
    <>
      {/* Header with Logo and Contact Support Button */}
      <motion.div
        variants={slideUpVariants}
        initial="visible"
        animate={isActive ? "hidden" : "visible"}
        exit="exit"
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        <Header />
      </motion.div>

      {/* Hotspot Banner Image */}
      <motion.div
        style={{
          backgroundImage: `url(${hotspot.banner_image_url})`,
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          width: "auto",
          position: "relative",
          padding: "1rem",
          margin: "0 1rem",
          overflow: "clip",
        }}
        variants={bannerVariants}
        initial="inactive"
        animate={isActive ? "active" : "inactive"}
        transition={{ duration: 1, ease: "easeInOut" }}
        exit="inactive"
        onClick={() => setIsActive(true)}
      >
        <VStack alignItems="center" w="100%">
          <HotspotHeaderTitle hotspot={hotspot} />
          <Box
            sx={{
              // Progressive blur effect
              "backdrop-filter": "blur(20px)",
              mask: "linear-gradient(transparent, black, black)",
              background:
                "linear-gradient(180deg, rgba(16, 16, 16, 0) 29.88%, #101010 100%)",
            }}
            h={isActive ? "50%" : "80%"}
            w="100%"
            position="absolute"
            bottom={0}
          />
          <VStack
            width="100%"
            h="auto"
            p="1rem"
            alignItems="center"
            position="absolute"
            bottom={0}
          >
            {/* Experience and capacity to show in active view */}
            <motion.div
              variants={fadeOutVariants}
              initial="hidden"
              animate={isActive ? "visible" : "hidden"}
              exit="hidden"
              transition={{ duration: 1, ease: "easeInOut" }}
              style={{
                width: "100%",
                marginBottom: "-0.5rem",
              }}
            >
              <HStack spacing={2} justifyContent="center">
                <Text
                  color="white"
                  textAlign="center"
                  fontWeight="400"
                  fontSize="md"
                >
                  A {hotspot.experience} Hotspot
                </Text>
                <HStack
                  borderRadius="0.5rem"
                  background="#10101080"
                  p="0.25rem 0.5rem"
                  backdropFilter="blur(12px)"
                  alignItems="center"
                >
                  <Users size={12} color="white" />
                  <Text color="white" fontSize="sm">
                    {hotspot.capacity}
                  </Text>
                </HStack>
              </HStack>
            </motion.div>

            {/* Host Preview */}
            <motion.div
              variants={fadeOutVariants}
              initial="visible"
              animate={isActive ? "hidden" : "visible"}
              exit="visible"
              transition={{ duration: 1, ease: "easeInOut" }}
              style={{
                width: "100%",
              }}
            >
              <HStack spacing={2} justifyContent="center">
                <Image
                  src={hotspot.host.profile_image_url}
                  boxSize="1.5rem"
                  borderRadius="full"
                />
                <Flex alignItems="center">
                  <Text color="white" fontSize="sm" fontWeight="700">
                    {hotspot.host.name}
                  </Text>
                  <Image src={verified} boxSize="12px" ml="4px" />
                  <Text fontSize="sm" fontWeight="400" color="#FFFFFF7A" ml={1}>
                    is hosting
                  </Text>
                </Flex>
              </HStack>
            </motion.div>

            {/* Hotspot Title */}
            <Text
              color="white"
              fontSize="2rem"
              textAlign="center"
              wordBreak="break-word"
              fontWeight={700}
              textTransform="capitalize"
            >
              {hotspot.name}
            </Text>

            {/* Experience Name and CTA to be shown in Inactive State */}
            <motion.div
              variants={fadeOutVariants}
              initial="visible"
              animate={isActive ? "hidden" : "visible"}
              exit="visible"
              transition={{ duration: 1, ease: "easeInOut" }}
              style={{
                width: "100%",
              }}
            >
              <Text
                color="white"
                textAlign="center"
                fontWeight="400"
                py="0.5rem"
                fontSize="md"
                mb="1rem"
              >
                A {hotspot.experience} Hotspot
              </Text>
              <RequestInviteDrawer hotspot={hotspot} />
            </motion.div>
          </VStack>
        </VStack>
      </motion.div>

      {/* Hotspot Detail Header with Back arrow and Contact Support */}
      <motion.div
        variants={slideUpVariants}
        initial="hidden"
        animate={!isActive ? "hidden" : "visible"}
        exit="exit"
        transition={{ duration: 1, ease: "easeInOut" }}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          zIndex: 100,
        }}
      >
        <HotspotDetailHeader onClickBack={() => setIsActive(false)} />
      </motion.div>
    </>
  );
};

export default HotspotPreviewCard;
