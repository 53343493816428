"use client";
import { ChakraProvider } from "@chakra-ui/react";
import DownloadRedirect from "@pages/DownloadRedirect";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Error from "@views/Error";
import { AxiosError } from "axios";
import "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, Navigate, useParams } from "react-router-dom";

import theme from "./theme.v2";
import About8club from "./views/About8club";
import HotspotShareLanding from "./views/HotspotShareLanding";

declare module "@tanstack/react-query" {
  interface Register {
    defaultError: AxiosError;
  }
}

const queryClient = new QueryClient();

const NavigateToShare: React.FC = () => {
  const { code } = useParams() as { code: string };
  if (code) {
    return <Navigate to={`/share?code=${code}`} replace />;
  }
  return <Error />;
};

const AppV2: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <div className="app-container">
          <ErrorBoundary fallback={<Error />}>
            <Routes>
              <Route path="/share/:code" element={<NavigateToShare />} />
              <Route path="/share" element={<HotspotShareLanding />} />
              <Route path="/download" element={<DownloadRedirect />} />
              <Route path="/info" element={<About8club />} />
              <Route path="/*" element={<DownloadRedirect />} />
            </Routes>
          </ErrorBoundary>
        </div>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default AppV2;
