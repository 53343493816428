import { Flex, Input, Text } from "@chakra-ui/react";

import useAttributionStore, {
  HotspotAttributionStore,
} from "../services/hotspot.attribution.service";

const AttributionPhoneInput = () => {
  const { phoneNumber, onChanged } = useAttributionStore(selectStore);
  return (
    <Flex justifyContent="center" alignItems="center" pb="1rem" mx="auto">
      <Text color="#FFFFFFB8" fontWeight="400" fontSize="2xl">
        +91 -
      </Text>
      <Input
        id="phone-number"
        type="number"
        placeholder="XXXXX XXXXX"
        value={phoneNumber}
        onChange={(e) => onChanged(e.target.value)}
        border="none"
        p={2}
        _focus={{ boxShadow: "none" }}
        _placeholder={{ color: "#FFFFFF29", fontSize: "1.3rem" }}
        color="white"
        fontSize="2xl"
        maxW="calc(1ch * 10.7)"
        fontWeight={700}
        inputMode="numeric"
      />
    </Flex>
  );
};

const selectStore = (state: HotspotAttributionStore) => ({
  phoneNumber: state.phoneInputValue,
  onChanged: state.setPhoneInputValue,
});

export default AttributionPhoneInput;
