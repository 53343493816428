import delight_feature from "@assets/delight_feature.png";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Button,
  Text,
  HStack,
  Box,
  Divider,
  Image,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { PartyPopper, Tickets } from "lucide-react";
import { useEffect, useRef } from "react";
import { FaAppStore } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

import AttributionPhoneInput from "../components/AttributionPhoneInput";
import { getCodeFromSearch } from "../repository/edge.server";
import { HotspotDetailsDTO } from "../repository/entities";
import useAttributionStore, {
  HotspotAttributionStore,
} from "../services/hotspot.attribution.service";

import displayToast from "@/utils/toasts";

const slideUpVariants = {
  hidden: { y: -100, opacity: 0, height: 0 },
  visible: { y: 0, opacity: 1, height: "auto" },
  exit: { y: -100, opacity: 0, height: 0 },
};

const slideDownVariants = {
  hidden: { top: -100, opacity: 0, height: 0 },
  visible: { top: 0, opacity: 1, height: "auto" },
  exit: { top: -100, opacity: 0, height: 0 },
};

interface RequestInviteDrawerProps {
  hotspot: HotspotDetailsDTO;
}

const RequestInviteDrawer: React.FC<RequestInviteDrawerProps> = ({
  hotspot,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  const { isLoading, isValidPhone, submitApplication, isSuccessful } =
    useAttributionStore(selectStore);

  const handleSubmit = () => {
    const code = getCodeFromSearch(search);
    if (!code) {
      displayToast("Invalid share code");
      return;
    }
    submitApplication(code);
  };

  const handleRedirectToInfo = () => {
    navigate("/info");
  };

  useEffect(() => {
    if (isSuccessful) {
      navigate("/download");
    }
  }, [isSuccessful, navigate]);

  return (
    <>
      <Button
        colorScheme="#ffffff"
        width="full"
        height="3.5rem"
        bgGradient="silver"
        border="1px solid rgba(128, 128, 128, 0.5)"
        background="rgba(16, 16, 16, 0.9)"
        borderRadius="0.5rem"
        fontWeight={400}
        leftIcon={<Tickets size={16} color="white" />}
        onClick={(e) => {
          e.stopPropagation(); // Stop bubbling of click to parent
          onOpen();
        }}
        p="1rem"
      >
        Request an Invite
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="bottom"
        onClose={onClose}
        finalFocusRef={btnRef}
        colorScheme="gray"
      >
        <DrawerOverlay backdropFilter="blur(12px)" />
        <DrawerContent backgroundColor="#101010" borderTop="2rem" p="1rem">
          <HStack backgroundColor="#FFFFFF05" borderRadius="0.75rem" p="1rem">
            <Box
              w="0.5rem"
              h="0.5rem"
              bg="#10DC03"
              borderRadius="full"
              boxShadow={`0 0 4px 4px rgba(16, 220, 3, 0.5)`}
              mr="0.25rem"
            />
            <Text
              color="white"
              fontSize="sm"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              <strong>{hotspot.name}</strong>&nbsp; by {hotspot.host.name}
            </Text>
          </HStack>

          <DrawerBody
            backgroundColor="#FFFFFF05"
            borderRadius="0.75rem"
            px="1rem"
            py="2rem"
            mt="1rem"
            overflow="hidden"
          >
            <Text color="white" fontSize="md" textAlign="center" mb="2rem">
              Enter phone number to continue
            </Text>
            {/* Phone Number Input Field */}
            <AttributionPhoneInput />

            <motion.div
              variants={slideUpVariants}
              initial="hidden"
              animate={isValidPhone ? "visible" : "hidden"}
              exit="exit"
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              <Button
                colorScheme="#ffffff"
                width="full"
                height="3.5rem"
                bgGradient="silver"
                border="1px solid rgba(128, 128, 128, 0.5)"
                background="rgba(16, 16, 16, 0.9)"
                borderRadius="0.5rem"
                fontWeight={400}
                leftIcon={<FaAppStore size={18} color="white" />}
                onClick={handleSubmit}
                p="1rem"
                my="1rem"
                isLoading={isLoading}
              >
                Download App to request
              </Button>
              <Divider color="#FFFFFF14" opacity={0.1} />
              <HStack justifyContent="center" mt="1rem">
                <Box
                  borderRadius="0.75rem"
                  background="#9196FF1A"
                  p="0.5rem"
                  mr="0.5rem"
                >
                  <PartyPopper size={24} color="#9D91F7" />
                </Box>
                <Text color="#FFFFFF7A" fontSize="xs" fontWeight={200}>
                  Download the app for updates on this Hotspot and invites to
                  others at 8club.
                </Text>
              </HStack>
            </motion.div>
          </DrawerBody>
          <motion.div
            variants={slideDownVariants}
            initial="hidden"
            animate={isOpen ? "visible" : "hidden"}
            exit="exit"
            transition={{ duration: 1, ease: "easeInOut", delay: 2 }}
            style={{
              position: "fixed",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Image
              src={delight_feature}
              alt="Delight Feature"
              transition="opacity 0.5s"
              onClick={handleRedirectToInfo}
            />
          </motion.div>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const selectStore = (state: HotspotAttributionStore) => ({
  isValidPhone: state.isPhoneNumberValid,
  submitApplication: state.submitAttributionRequest,
  isLoading: state.isProcessingRequest,
  isSuccessful: state.isSuccessful,
});

export default RequestInviteDrawer;
