import { Box, HStack } from "@chakra-ui/react";
import { ArrowLeft, Headset } from "lucide-react";

import { whatsappLink } from "@/config";

interface HotspotDetailHeaderProps {
  onClickBack: () => void;
}

const HotspotDetailHeader: React.FC<HotspotDetailHeaderProps> = ({
  onClickBack,
}) => {
  return (
    <HStack justifyContent="space-between" p="1rem">
      <Box
        borderRadius="0.5rem"
        background="#10101080"
        p="0.5rem"
        backdropFilter="blur(16px)"
        onClick={onClickBack}
      >
        <ArrowLeft size={16} color="white" />
      </Box>
      <Box
        borderRadius="0.5rem"
        background="#10101080"
        p="0.5rem"
        backdropFilter="blur(16px)"
      >
        <Headset
          size={16}
          color="white"
          onClick={() => window.open(whatsappLink, "_blank")}
        />
      </Box>
    </HStack>
  );
};

export default HotspotDetailHeader;
