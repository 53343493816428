import { Box, Container, Divider, Flex, HStack, Text } from "@chakra-ui/react";
import { CalendarCheck, MapPin, Users } from "lucide-react";
import React from "react";

import { HotspotDetailsDTO } from "../repository/entities";
import { getShortDate } from "../utils/date.time.utils";

interface HotspotHeaderTitleProps {
  hotspot: HotspotDetailsDTO;
}

const HotspotHeaderTitle: React.FC<HotspotHeaderTitleProps> = ({ hotspot }) => {
  return (
    <Container
      borderRadius="0.5rem"
      py="0.75rem"
      px="1rem"
      backdropFilter="auto"
      width="fit-content"
      display="inline-flex"
      position="absolute"
      top="1rem"
      zIndex={2}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundColor="#10101080"
        backdropFilter="blur(16px)"
        borderRadius="0.5rem"
        zIndex={-1}
      />
      <HStack
        spacing={2}
        alignItems="center"
        justifyContent="space-around"
        w="100%"
      >
        <Box
          w="0.5rem"
          h="0.5rem"
          bg="#10DC03"
          borderRadius="full"
          boxShadow={`0 0 4px 4px rgba(16, 220, 3, 0.5)`}
        />
        <HStack spacing={2} ml={1}>
          <Flex alignItems="center">
            <Users size={12} color="white" />
            <Text fontWeight="400" color="white" ml={1} fontSize="xs">
              {hotspot.capacity}
            </Text>
          </Flex>
          <Divider orientation="vertical" height="12px" borderColor="white" />
          <Flex alignItems="center">
            <CalendarCheck size={12} color="white" />
            <Text fontWeight="400" color="white" ml={1} fontSize="xs">
              {getShortDate(hotspot?.start_datetime)}
            </Text>
          </Flex>
          <Divider orientation="vertical" height="12px" borderColor="white" />
          <Flex alignItems="center">
            <MapPin size={12} color="white" />
            <Text fontWeight="400" color="white" ml={1} fontSize="xs">
              {hotspot.address.city}
            </Text>
          </Flex>
        </HStack>
      </HStack>
    </Container>
  );
};

export default HotspotHeaderTitle;
