import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { HotspotDetailsDTO } from "./entities";

import baseURL from "@/config";

const axiosInstance = axios.create({
  baseURL,
  headers: { channel: "web" },
});

const getHotspotFromShareCode = async (
  code: string | null
): Promise<HotspotDetailsDTO | undefined> => {
  if (!code || code.length === 0) {
    return undefined;
  }

  try {
    const { data } = await axiosInstance.get(`/v2/hotspots/share-code/${code}`);
    return HotspotDetailsDTO.fromJson(data.data);
  } catch (e: unknown) {
    Sentry.captureException(e);
  }
};

export const useGetHotspotFromShareCode = (search: string) => {
  const code = getCodeFromSearch(search);

  return useQuery({
    queryKey: ["hotspot-share", code],
    queryFn: () => getHotspotFromShareCode(code),
  });
};

export const getCodeFromSearch = (search: string): string | null => {
  const params = new URLSearchParams(search);
  return params.get("code");
};

export const recordHotspotAttribution = async (code: string, phone: string) => {
  // Handle the error in the calling function
  await axiosInstance.post("/v1/hotspots/record-notional-interest", {
    country_code: "+91",
    phone_number: phone,
    share_code: code,
  });
};
