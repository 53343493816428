import "./instrument";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./global.css";
import "react-toastify/dist/ReactToastify.css";
import AppV2 from "./v2/App.v2";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <BrowserRouter>
      <ToastContainer />
      <AppV2 />
    </BrowserRouter>
  </StrictMode>
);
