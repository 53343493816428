import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { useGetHotspotFromShareCode } from "../repository/edge.server";

import HotspotDetailPage from "./HotspotDetailPage";
import HotspotPreviewCard from "./HotspotPreviewCard";

import Spin from "@/components/Spinner";
import Error from "@/views/Error";

const HotspotShareLanding = () => {
  const { search } = useLocation();
  const {
    data: hotspot,
    isError,
    isLoading,
    error,
  } = useGetHotspotFromShareCode(search);

  const [isActive, setIsActive] = useState(false);

  if (isLoading) {
    return <Spin />;
  }

  if (isError || !hotspot) {
    return <Error error={error!} />;
  }

  return (
    <Box
      w="100%"
      h="100%"
      backgroundColor="#101010"
      overflowY="auto"
      sx={{ scrollbarWidth: "none" }}
    >
      <HotspotPreviewCard
        isActive={isActive}
        setIsActive={setIsActive}
        hotspot={hotspot}
      />
      <HotspotDetailPage isActive={isActive} hotspot={hotspot} />
    </Box>
  );
};

export default HotspotShareLanding;
